import { initFederation } from '@angular-architects/native-federation';
declare global {
  interface Window {
    global: typeof window;
    process: { env: object } & NodeJS.Process;
  }
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace NodeJS {
    interface ProcessEnv {
      [key: string]: string | undefined;
    }
  }
}
if (window) {
  window.global = window;
  window.process = { env: {} } as { env: object } & NodeJS.Process;
}

initFederation('/assets/federation.manifest.json')
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err))
  .then(() => import('./bootstrap'))
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));
